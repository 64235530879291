.about__subtitle {
    padding: 10px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 600;
}

.about__img {
    text-align: center;
}

.about__checklist {
    font-size: $global-font-size - 2;
}


.about__checklist__item {
    min-height: 40px;

    & .checklist__icon__wrapper {
        font-size: $global-font-size + 4;
        min-width: 40px;
        color: green;
    }

    & .text__wrapper {
        min-width: 210px;
        padding: 5px;
    }
}