.app__overlay {
  display: none;
  position: fixed;
  top: $global-app-header-height;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $global-black-opacity-1;
  z-index: $global-overlay-z-index;

  &.show {
    display: block;
  }
}
