.section-title__container {
  text-align: center;
  padding: 30px 0 20px 0;
  position: absolute;
  top: 0;
}

.section-title {
  position: relative;
  display: inline;
  text-align: center;
  font-size: 20px;
  letter-spacing: 4.5px;
}

.section__container {
  padding: 0 $global-content-padding 30px;
  padding-bottom: 30px;
  margin-top: $global-section-container-top-margin;
}