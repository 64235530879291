.header__nav {
  height: 100%;
  width: 100%;
  z-index: initial;
  background-color: transparent;
  color: $global-white;

  & .logo__wrapper {
    height: $global-app-header-height;
    width: $global-app-header-height;
  }

  & .logo__wrapper .nav__logo {
    height: 100%;
    width: 100%;
    color: inherit;
    text-align: center;
    line-height: $global-app-header-height;
  }

  & .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $global-white-5;
    z-index: $global-header-nav-menu-z-index;
    margin-top: ($global-mobile-nav-menu-height + $global-app-header-height) *
      -1;
    transition: 0.5s;
  }

  & .nav__menu.show {
    margin-top: $global-app-header-height;
    transition: ease-in 0.4s;
  }

  & .nav__menu.show .nav__menu__close__btn__container {
    color: $global-primary-color;
  }

  & .nav__menu .nav__list .nav__item {
    height: $global-app-header-nav-item-height;
    width: 100%;
    // border-bottom: 1px solid $global-dirty-white;
  }
  // & .nav__menu .nav__list .nav__item:hover {
  //   background-color: red;
  // }

  & .nav__menu .nav__list .nav__item .nav__link {
    display: block;
    height: 100%;
    width: 100%;
    padding: 0 $global-content-padding;
    line-height: $global-app-header-nav-item-height;
    color: $global-primary-color;
  }

  & .nav__menu .nav__list .nav__item .nav__link.active {
    background-color: $global-white-6;
  }

  & .nav__toggle {
    height: $global-app-header-height;
    width: $global-app-header-height;
  }
}
