*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  font-size: $global-font-size;
}

body {
  font-family: $global-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $global-white;
  color: $global-primary-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max__width {
  max-width: $global-app-max-width;
  margin-left: auto;
  margin-right: auto;
}

.min__section__height {
  min-height: $global-min-section-height;
}

.max__section__height {
  max-height: $global-max-section-height;
}

.no__select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// For re presentational testing
.test {
  display: none;
  position: fixed;
  bottom: 0;
  background-color: black;
  color: white;
  font-size: 15px;
  border: 2px solid gray;
  & table {
    border: 1px solid gray;
  }
  & table tr td {
    padding: 0 5px;
    border: 1px solid gray;
  }
}
