.text__bold {
    font-weight: bold;
}

.text__fw__500 {
    font-weight: 500;
}

.text__uppercase {
    text-transform: uppercase;
}

.text__capitalize {
    text-transform: capitalize;
}
