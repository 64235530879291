.flex {
    display: flex;
}

.flex__row {
    display: flex;
    flex-direction: row;
}

.flex__column {
    display: flex;
    flex-direction: column;
}

.flex__justify__center {
    justify-content: center;
}

.flex__align__center {
    align-items: center;
}
