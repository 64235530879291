.l-header {
    width: 100%;
    height: $global-app-header-height;
    position: absolute;
    top:0;
    background-color: transparent;
    z-index: $global-header-z-index;
    color: $global-white;

    &.fixed {
        position: fixed;
    }

    &.fixed .header__nav {
        height: 100%;
        width: 100%;
        z-index: initial;
        background-color: $global-white;
        color: $global-primary-color;
        box-shadow: 0 1px 4px $global-black-opacity-4;
        // border-bottom: 1px solid $global-dirty-white;
        transition: 0.2s;
    }
}
