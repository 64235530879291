.btn {
  height: 37px;
  padding: 0 10px;
  display: block;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;

  &.rounded {
    border-radius: 5px;
  }
}

.btn.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn.btn-primary {
    color: #fff;
    background-color: $global-primary-color;
    border-color: $global-primary-color;
  }
