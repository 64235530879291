.contact__input {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid $global-primary-color;
    outline: none;
    margin-bottom: 20px;
}

.contact__submit__button {
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
}