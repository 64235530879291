$global-primary-color:#0E2431;
$global-secondary-color:#4070F4;

$global-white: #fff;
$global-black: #000;
$global-gray: #495057;
$global-dirty-white: #DBDBDB;
$global-light-gray: #7e7d7d;
$global-silver: #C0C0C0;

$global-silver-1: #C8C8C8;
$global-silver-2: #D0D0D0;

$global-white-5:#ececec;
$global-white-6:#cac9c9;

$global-black-opacity-1: rgba(0, 0, 0, 0.1);
$global-black-opacity-2: rgba(0, 0, 0, 0.2);
$global-black-opacity-3: rgba(0, 0, 0, 0.3);
$global-black-opacity-4: rgba(0, 0, 0, 0.4);
$global-black-opacity-5: rgba(0, 0, 0, 0.5);
$global-black-opacity-6: rgba(0, 0, 0, 0.6);
$global-black-opacity-7: rgba(0, 0, 0, 0.7);
$global-black-opacity-8: rgba(0, 0, 0, 0.8);

$global-gray-opacity-15: rgba(146, 161, 176, .15);

$global-font-size: 16px;
$global-font-family:'Roboto',sans-serif;

$global-content-padding: 30px;

$global-app-min-width: 320px;
$global-app-max-width: 1170px;

$global-button-height: 35px;

$global-app-header-height: 80px;
$global-app-header-nav-item-height: 50px;

$global-min-section-height: calc(100vh - #{$global-app-header-height});
$global-max-section-height: 720px;

$global-mobile-nav-menu-width: 250px;
$global-mobile-nav-menu-height: $global-app-header-nav-item-height * 5;

$global-app-footer-height: 120px;

$global-header-z-index: 10;
$global-overlay-z-index: -1;
$global-header-nav-menu-z-index: -1;

$global-section-container-top-margin: 80px;
