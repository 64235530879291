.works__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.works__item {
    // border: 1px solid red;
    // box-shadow: 0 1px 1px $global-black-opacity-4;
    // border-radius: 5px;
    padding: 5px;
    // max-width: 472px;
    // max-height: 472px;
    max-width: 472px;
    max-height: 472px;
}

.works__item__image__container {
    & img {
        border-radius: 5px;
        box-shadow: 0 4px 8px $global-black-opacity-4;
        width: 100%;
        height: 100%;
    }
}