.footer__container {
    height: 100%;
    text-align: center;
}

.footer__title {
    text-align: center;
    padding: 10px;
    letter-spacing: 4.5px
}

.footer__social {
    text-align: center;
    padding: 10px 0;
}

.footer__icon {
    font-size: $global-font-size + 10;
    color: $global-white;
    margin: 5px 10px;
}

.footer__copyrights {
    padding: 10px;
    text-align: center;
    font-size: $global-font-size - 5;
}