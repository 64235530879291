.home__overlay {
  position: absolute;
  background-color: $global-black-opacity-8;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.home__container {
  height: 100%;
  color: $global-white;
  padding: $global-content-padding;
}

.home__heading {
  letter-spacing: 1px;
  margin-top: -50px;
}

.home__title-color {
  color: $global-white;
}

.home__sub__title {
  display: block;
  font-size: $global-font-size - 2;
  font-weight: 300;
  letter-spacing: 4.5px;
}

.home__name {
  display: block;
  font-size: $global-font-size + 18;
  margin-top: 10px;
}

.home__job__description {
  display: block;
  color: $global-light-gray;
  font-size: $global-font-size;
}

.home__call__to__action__button {
  height: $global-button-height;
  width: 95px;
  margin-top: 30px;
  font-size: $global-font-size - 2;
  line-height: $global-button-height;
  background-color: $global-secondary-color;
  border-radius: 4px;
  color: $global-white;
}
