.skills__subtitle {
    padding: 10px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 600;
}

.skills__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & .skills__item {
        text-align: center;
        padding: 5px;
        height: 80px;
        width: 80px;
        margin: 5px;
        border-radius: 50%;
        box-shadow: 0 4px 8px $global-black-opacity-4;
    }

    & .skills__item .skills__item__image__container {
        height: 70px;
        width: 70px;
    }

    & .skills__item .skills__item__image__container.bg-silver {
        background-color: $global-silver-2;
    }

    & .skills__item img {
        max-height:50px;
        max-width: 50px;
        vertical-align: middle;
    }
}

.skills__image__helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}